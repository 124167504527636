<template lang="html">


<div class='overlay'>
  <div class="information">
    <h1>Quality defines US.</h1>

    <p>We are a domestic consulting company with a methodology applied in the largest domestic and foreign companies. We provide services in the field of HR management, Organizational development, Assessment of human resources and managerial potential, Performance management, Executive search, Talent management in the organization and Strategic management of HR functions in companies, all with the aim of successfully introducing changes and ensuring continuity in business results. .</p>
    <p>We offer our clients the highest quality, innovative and practical solutions in the field of human resource management and organizational development, building a long-term partnership.</p>
    <p>Our Vision is that, with our unique approach and the support of a top team of professionals, we inspire our clients to achieve top results.</p>
    <p>In the KS SERVICES section, you have the option of sending a direct inquiry to our Company for certain services by filling out a form.</p>
    <p>In the KS CANDIDATES section, you can send us your CV, which we will save in our database so that we can contact you if a position corresponding to your profile is opened.</p>
  </div>

</div>





</template>

<script>
// @ is an alias to /src
import {mapActions} from 'vuex';

export default {
  name: 'Home',
  components: {

  },
  methods:{
    ...mapActions(['setEng']),
  },
  created(){
    this.setEng();
  },
  beforeCreate: function() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.body.className = 'homeMobile';
    } else {
      document.body.className = 'home';
    }
  }
}
</script>
<style lang="css" scoped>
.overlay {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;


  }
  .information{
    width:80%;
    color:white;
    text-align: center;
    margin: auto;
    background-color: #335577;
    border-radius: 50px;
    margin-top: 50px;
    padding: 5px
  }



</style>
